import React from "react";

function NotFound() {
  return (
    <>
      <h1>Not Found</h1>
      <p>Sorry...</p>
    </>
  );
}

export default NotFound;
