import React from "react";
import orgin from '../assets/img/icon/origin.png';
import vision from '../assets/img/icon/vision.png';
import mission from '../assets/img/icon/mission.png';
import enterprise from '../assets/img/enterprises.jpg';
import foundation from '../assets/img/foundation.jpg';
import slider1 from '../assets/img/slider/slider1.png';
import slider2 from '../assets/img/slider/slider2.png';
import slider3 from '../assets/img/slider/slider3.png';
import cash_flow from '../assets/img/icon/cash_flow.png';
import cash_flow_hover from '../assets/img/icon/cash_flow_hover.png';
import capital from '../assets/img/icon/capital.png';
import capital_hover from '../assets/img/icon/capital_hover.png';
import compounding from '../assets/img/icon/compounding.png';
import compounding_hover from '../assets/img/icon/compounding_hover.png';
import diversification from '../assets/img/icon/diversification.png';
import soundIcon from "../assets/img/sound_icon.png"
import diversification_hover from '../assets/img/icon/diversification_hover.png';
//import nanbanGulf from "../assets/img/Nanban_Logo_Gulf.png"
import Nanban_Entertainment from "../assets/img/nanban-entertainment.png"

import nanban_popup_banner from "../assets/img/nanban-popup-banner.jpg";
import nanban_popup_banner_mobile from "../assets/img/nanban-popup-banner-mobile.png";
import nanban_popup_banner_ipad from "../assets/img/nanban-popup-banner-ipad.png";
import nanban_popup_banner_ipad_land from "../assets/img/nanban-popup-banner-ipad-land.png";

import { MyDiv, Section, PTag, HeadingOne } from '../components/common/Common';

function Home() {
  return (
    <>
      <header >
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" >
          <div className="carousel-inner">

            <div className="carousel-item active" data-interval="10000">
              <div className="carousel-caption container" id="overlay">
                <h1 className="masthead-heading  mb-0">Freedom to <span className="head-color">Dream</span></h1>
                <p className="masthead-subheading font-weight-light mb-0">Nanban has built a platform where dreams come true, regardless of net worth,<br />
                  nationality, religious or political beliefs.</p>
              </div>
              <img className="sliders-img" src={slider1} alt="Slider 2" />

            </div>

            <div className="carousel-item" data-interval="10000"> 
              <div className="carousel-caption container">
                <h1 className="masthead-heading  mb-0">Pursue your <span className="head-color">Passion</span></h1>
                <p className="masthead-subheading font-weight-light mb-0">Following your Passion will ultimately lead you to your  life's purpose.</p>
              </div>
              <img className="sliders-img" src={slider2} alt="Slider 3" />

            </div>

            <div className="carousel-item" data-interval="10000">
              <div className="carousel-caption container">
                <h1 className="masthead-heading  mb-0">Power to be <span className="head-color">Free</span></h1>
                <p className="masthead-subheading font-weight-light mb-0">Nanban wants to provide the ability for everyone to live a beautiful
        and worry-free life.</p>
              </div>
              <img className="sliders-img" src={slider3} alt="Slider 1" />
            </div>
          </div>
      </div>
</header>

    <Section className="gulfAnnouncement">
          <MyDiv className="container content-section">
            <MyDiv className="row">            
              {/* <MyDiv className="col-md-4 col-sm-3 gulf-image">                
              <img className="sound-img" src={Nanban_Entertainment} alt="Nanban Entertainment" />
              </MyDiv> */}
              <MyDiv className="col-md-12 col-sm-9 gulf-content">                
                  {/* <h2>Introducing Nanban Gulf</h2> */}
                  <h2>News And Announcements</h2>
                
                  <p className="gulfAnnouncement.gulf-content p">Please go to the NEWS (<a href="https://www.nanban.com/news" target="_blank">Nanban.com/news</a>) page for updates regarding Nanban Ventures. Please use the subscribe feature to get email notifications whenever new updates are made available.</p>
                 
                    {/* <h3>What is the status of the SEC investigation?</h3>
                    <br />
                    <p className="gulfAnnouncement.gulf-content p">• Per the March 7, 2024, Court ruling, the Nanban leadership team has been dismissed and the designated receiver now has oversight of Nanban Ventures and the founder companies (GSM Eternal LLC, a.k.a. NorthStars FinTech, Himalayan FinTech LLC, and Centum FinTech LLC, a.k.a. Sunshines FinTech LLC).</p>
                    <p className="gulfAnnouncement.gulf-content p">• The Court has also scheduled a mediation meeting between Nanban and the SEC on June 21, 2024, with mediation statements to be submitted by June 7, 2024. These activities could be preceded by pre-mediation dialogue between Nanban and the SEC. </p>
                <br />
                <h3>What role will Nanban leadership team play going forward? </h3>
                  <br />
                  <p className="gulfAnnouncement.gulf-content p">The Nanban leadership team will continue to provide all necessary information to the receiver as requested while actively pursuing other legal options to resolve the situation.   </p>
                  <br />
                  <h3>What are the next steps and time frame regarding this situation? </h3>
                  <br />
                  <p className="gulfAnnouncement.gulf-content p"> Per the Court's ruling, the designated receiver is to provide a 90-day report to the Court. The expectation is that the receiver will contact investors regarding further updates. </p>
                  <br />
                  <h3>How will Nanban communicate with investors and other interested parties going forward?  </h3>
                  <p className="gulfAnnouncement.gulf-content p">Nanban will provide periodic updates regarding this situation on the www.nanban.com website. Since Nanban leadership no longer controls Nanban Ventures as per Court ruling, Nanban is unable to communicate with investors using the Nanban Ventures main mailbox as was previous standard practice. The www.nanban.com website will be enhanced to provide updates and alerts to those who want to stay up to date on the case between Nanban and the SEC.   </p>
                  <br />
                  <h3>When will the 2023 K-1 forms be provided to Ventures investors? </h3>
                  <p className="gulfAnnouncement.gulf-content p">The expectation is that the receiver will update investors when K-1 forms will be provided. Nanban is committed to providing all necessary support to the receiver so that the forms can be made available as soon as possible. </p>
                  <br />
                  <br />
                  <div className="border my-5 border-secondary w-100" bis_skin_checked="1"></div>
                  <br />
                  <br />
                  <h2>NANBAN GROUP RESPONDS TO COURT RULING ON RECEIVERSHIP MOTION AGAINST NANBAN VENTURES, Et al.</h2>
                  <br />
                  <p className="gulfAnnouncement.gulf-content p">Plano, TX - March 13, 2024 -- Nanban Group, an investment firm dedicated to helping investors achieve their financial goals, wishes to inform its esteemed investors and the general public of a recent development that occurred over the weekend.</p>
                  <p className="gulfAnnouncement.gulf-content p">We regret to inform you that the court has ruled in favor of the receivership motion filed by the Securities and Exchange Commission (SEC) against Nanban Ventures LLC, et al. in our efforts to unfreeze assets. However, the Court has allowed for our ability to refile to release assets or ask the receiver to do so. This decision was made despite our request for an in-person hearing and was based upon, in large part, the evidence presented by the SEC in the original ex-parte Temporary Restraining Order--TRO in October 2023. In the anticipated hearing, we planned to present arguments against both the TRO and receivership.</p>
                  <p className="gulfAnnouncement.gulf-content p">However, the Court's receivership order effectively dismissed the existing Nanban Ventures team and ordered the receiver to take charge of Nanban Ventures and its founder companies (GSM Eternal LLC, a.k.a. NorthStars FinTech, Himalayan FinTech LLC, and Centum FinTech LLC, a.k.a. Sunshines FinTech LLC). We are committed to working with the appointed receiver team to ensure they are well-equipped to secure the best offers for our investors while in parallel we work out the next steps to seek appropriate legal relief. </p>
                  <p className="gulfAnnouncement.gulf-content p">We understand that this news may be disappointing, but we want to assure you we will continue to do everything in our power to bring this matter to a satisfactory resolution by continuing to work with the SEC and the Court. We are committed to our investors and will continue to work diligently to ensure the best possible outcome. </p>
                  <p className="gulfAnnouncement.gulf-content p">We appreciate your continued understanding, support, and patience during this time.</p>
                  <p className="gulfAnnouncement.gulf-content p">We request our investors to visit <a href="https://www.nanban.com" className="email-link" target="_blank" rel="noopener noreferrer" bis_skin_checked="1">www.nanban.com</a> often to stay informed on developments in this court case, as we are committed to maintaining transparency.</p>
                  <br />
                  <br />
                  <p className="gulfAnnouncement.gulf-content p">Sincerely,</p>
                  <p className="gulfAnnouncement.gulf-content p">Nanban Group</p>
                  <br />
                  <p className="gulfAnnouncement.gulf-content p">For media inquiries, please contact <a className="email-link" href="mailto:media@nanban.com" bis_skin_checked="1">media@nanban.com</a></p> */}

              </MyDiv>
            </MyDiv>
          </MyDiv>
        </Section>

       <section className="page-section bg-primary1 text-white mb-0" id="about">
          <div className="container">
            <div id="myCarousel" className="carousel slide" data-bs-interval="false">
              {/* <ol className="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
              </ol> */}
              <div className="carousel-inner"> 
                <div className="item active">
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 ms-auto text-center"><img className="slider-img" src={orgin} alt="Slider 1" /></div>
                    <div className="col-lg-6 col-sm-9 me-auto slider-content"><h3>Origin</h3><p className="lead">The word Nanban /nʌnbɑːn/ translates to: 'friend' in the classic Indian language of Tamil. Nanban Group of Companies, and its subsidiaries, were created with the sole vision of helping our community attain financial freedom while supporting the underprivileged through socially-relevant initiatives.</p></div>
                  </div>
                </div>

                {/* <div className="item">
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 ms-auto text-center"><img className="slider-img" src={vision} alt="Slider 2" /></div>
                    <div className="col-lg-6 col-sm-9 me-auto slider-content"><h3>Vision</h3><p className="lead">To become a highly trusted, truly transformative investment platform that brings “Cash Flow” based strategies to every Nanban irrespective of their net worth in a responsible, people-first and purpose driven enterprise.<br /></p></div>
                  </div>
                </div>

                <div className="item">
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 ms-auto text-center"><img className="slider-img" src={mission} alt="Slider 3" /></div>
                    <div className="col-lg-6 col-sm-9 me-auto slider-content"><h3>Mission</h3><p className="lead">To share low risk investment strategies with all Nanbans that can produce consistent CASH FLOW and help fulfill the financial dreams of people at all levels of wealth by putting their interest first and foremost with the highest standards of ethics and integrity</p></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <Section className="home-page flip-section bg-white text-white mb-0" id="about">
          <MyDiv className="container">
            <MyDiv className="row">
              <MyDiv className="col-md-6">
                <MyDiv className="grid home-page">
                  <figure className="effect-enterprise">
                    <figcaption>
                      <h2>Nanban <br /><span className="subhead"> <a href="" > Group of Companies </a></span> </h2>
                    </figcaption>
                    <figure className="img_flip img_flip-hor">
                      <img className="flip-img" src={enterprise} alt="Enterprise" />
                      <figcaption className="fig1">
                        <h3>Nanban Group of Companies is our for-profit business with four verticals:</h3>
                        {/* <a href="https://investor.nanbaninvestments.com/login" target="_blank">Hedge Fund <br /> <span className="small-head">Nanban Investments</span></a> */}
                        <a href="https://nanbanventures.com/" target="_blank">Nanban Ventures <br /> <span className="small-head">Venture Capital</span></a>
                        {/* <a href="https://www.nanbanrealty.com/" target="_blank">Real Estate <br /> <span className="small-head">Nanban Realty</span></a> */}
                        <a href="https://nanbanchola.com/" target="_blank">Nanban Chola <br /> <span className="small-head">Land Development</span></a>
                        <a href="https://nanbanesg.com/" target="_blank">Nanban ESG <br /> <span className="small-head">Sustainable Energy Solutions</span></a>
                        {/* <a href="https://nanbanrealty.com/"  target="_blank">Orion Nanban Realty<br /> <span className="small-head">Real Estate</span></a>
                        <a >Nanban Cares<br /> <span className="small-head">Healthcare</span></a> */}
                        <a href="https://www.nanbanentertainment.com" target="_blank"> Nanban Entertainment<br /> <span className="small-head">Entertainment & Global Talent</span></a>
                      </figcaption>
                    </figure>
                  </figure>
                </MyDiv>
              </MyDiv>
              <MyDiv className="col-md-6">
                <MyDiv className="grid home-page">
                  <figure className="effect-foundation">
                    <figcaption>
                      <h2>Nanban <br /> <span className="subhead"> <a href="https://nanbanfoundation.org/" target="_blank" >Foundation</a></span></h2>
                    </figcaption>
                    <figure className="img_flip1 img_flip-hor">
                      <img className="flip-img" src={foundation} alt="flip img" />
                      <figcaption className="fig2">
                        <h3>Nanban Foundation is our non-profit arm focusing on:</h3>
                        <a href="https://nanbanfoundation.org/cash-flow-strategies/" target="_blank">Financial Education <br /> <span className="small-head">Cash flow strategies </span></a>
                        {/* <a href="http://www.nanbanmfmn.org/" target="_blank">Mothers For Mother Nature<br /> <span className="small-head">Converting to Organic Farming</span></a> */}
                        <a href="http://www.nanbanmfmn.org/" target="_blank">Mothers For Mother Nature<br /> <span className="small-head">Promoting sustainable agriculture</span></a>
                        {/* <a href="https://nanbansportsfoundation.org/" target="_blank"> Nanban Sports Foundation <br /> <span className="small-head">Nurturing grass root sports talent and creating champions</span></a> */}
                      </figcaption>
                    </figure>
                  </figure>
                </MyDiv>
              </MyDiv>
            </MyDiv>
          </MyDiv>
        </Section>

        {/* <Section className="content-section mb-0 flip-desc-section">
          <MyDiv className="container">
            <MyDiv className="row">
              <MyDiv className="col-md-12">
                <h4 className="flip-description">At <b className="desc">Nanban</b>, we believe there is truth in the well-known proverb, <span className="desc1">“A friend in need is a friend indeed.”</span> As such, we have prioritized service and made it our mission to reach out to those in need.</h4>
              </MyDiv>
            </MyDiv>
          </MyDiv>
          </Section> */}





        <Section className="content-section bg-white text-white mb-0" id="about">
          <MyDiv className="container">
            <MyDiv className="row">
              <MyDiv className="col-md-12">
                <h4 className="flip-description">At <b className="desc">Nanban</b>, we believe there is truth in the well-known proverb, <span className="desc1">“A friend in need is a friend indeed.”</span> As such, we have prioritized service and made it our mission to reach out to those in need.</h4>
              </MyDiv>
              {/* <MyDiv className="divider-custom divider-light">
                <MyDiv className="divider-custom-line"></MyDiv>
                <MyDiv className="divider-custom-icon">Our Building Blocks</MyDiv>
                <MyDiv className="divider-custom-line"></MyDiv>
              </MyDiv> */}
            </MyDiv>
          </MyDiv>
        </Section>


        {/* <Section className="content-section bg-white text-white mb-0" id="about">
          <MyDiv className="container">
            <MyDiv className="row">
              <MyDiv className="col-md-12 works">
                <MyDiv className="mobile-working">
                  <MyDiv className="mobile-working1" ><a>
                    <img className="flow-img" src={cash_flow} alt="" />
                    <img className="flow-img" src={cash_flow_hover} alt="" className="img-top" />
                    <span className="tooltiptext">
                      <h3 className="tooltip-head">Cash Flow</h3><MyDiv className="lines"></MyDiv>
                      <PTag className="tooltip-para">Being able to collect cash from an asset is the basis of everything we do here at Nanban. Our preferred strategy is to collect cash from the world's most significant assets, the S&P 500 (an asset class that will never go to zero but rather constantly rebalances itself automatically). We view this as collecting rent from a 500 apartment real estate asset.</PTag>
                    </span></a>
                  </MyDiv>
                  <MyDiv className="mobile-working1"><a>
                    <img className="flow-img" src={capital} alt="" />
                    <img className="flow-img" src={capital_hover} alt="" className="img-top" />
                    <span className="tooltiptext1">
                      <h3 className="tooltip-head">Capital Protection</h3><MyDiv className="lines"></MyDiv>
                      <PTag className="tooltip-para">Most investors look for capital appreciation as the premier driver of wealth generation. Although appreciation is critical, our focus is to always preserve the capital by buying downside protection (hedging). Leaving your hard-earned money unprotected could result in a quick drawdown of 50% would require a 100% increase - merely to break even from the initial loss. </PTag>
                    </span></a>
                  </MyDiv>
                  <MyDiv className="mobile-working1"><a>
                    <img className="flow-img" src={compounding} alt="" />
                    <img className="flow-img" src={compounding_hover} alt="" className="img-top" />
                    <span className="tooltiptext2">
                      <h3 className="tooltip-head">Compounding Effect</h3><MyDiv className="lines"></MyDiv>
                      <PTag className="tooltip-para">One of the secrets to building wealth is to allow your capital to continue to work by compounding itself over a more extended period. One of the most brilliant people in that ever lived, Albert Einstein, once said: "Compound interest is the eighth wonder of the world." </PTag>
                    </span>
                  </a>
                  </MyDiv>
                  <MyDiv className="mobile-working1"><a>
                    <img className="flow-img" src={diversification} alt="" />
                    <img className="flow-img" src={diversification_hover} alt="" className="img-top" />
                    <span className="tooltiptext3">
                      <h3 className="tooltip-head">Diversification</h3><MyDiv className="lines"></MyDiv>
                      <PTag className="tooltip-para">Building a life with consistent cash flow allows you to live your life without worrying about the financial impact it would "normally" would have. Nanbans preferred way of life is to devote our time to philanthropic work - which is why we started Nanban Foundation; a no-strings-attached platform for charitable contributions. </PTag>
                    </span> </a>
                  </MyDiv>
                </MyDiv>
              </MyDiv>
            </MyDiv>
          </MyDiv>
        </Section> */}

        

        {/* <div className="portfolio-modal modal  fade home-popup " id="modal-popup" aria-labelledby="modal-popup" aria-hidden="true" >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button"  data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body  model_overlay">
                        <div className="container">
                        
                            <div className="d-block d-md-none">
                              <img  src={nanban_popup_banner_mobile}  useMap="#image_map_mobile" alt="Story" />
                              <map name="image_map_mobile">
                              <area alt="" title="" href="nanban-ambassador" coords="109,486,206,515" shape="rect"/>
                              </map>
                            </div>
                            <div className="d-none d-md-block d-lg-none">
                              <img  src={nanban_popup_banner_ipad}  useMap="#image_map_ipad" alt="Story" />
                              <map name="image_map_ipad">
                              <area alt="brand" title="brand" href="nanban-ambassador" coords="346,273,420,294" shape="rect"/>
                              </map>
                            </div>
                            
                            <div className="d-none d-lg-block d-xxl-none">
                              <img  src={nanban_popup_banner_ipad_land}  useMap="#image_map_ipad1" alt="Story" />
                              <map name="image_map_ipad1">
                              <area alt="brand" title="brand"  href="nanban-ambassador" coords="540,426,654,460" shape="rect"/>
                              </map>
                            </div>
                            <div className="d-none d-xxl-block">
                              <img  src={nanban_popup_banner}  useMap="#image_map" alt="Story" />
                              <map name="image_map">
                                  <area alt="brand" title="brand" href="nanban-ambassador" coords="780,387,888,423" shape="rect"/>
                              </map>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}


    </>
      );
    }
    
    export default Home;
