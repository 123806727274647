import React, { useState, useMemo, useEffect } from "react";
import ApiService from "../Api.service.js";
import Pagination from "../Pagination.js";
import { MyDiv, Section, PTag, HeadingOne } from "../components/common/Common.js";
import media_banner from "../assets/img/slider/Media_Banner_Img.jpg";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// ES Modules
import parse from "html-react-parser";

import { NavLink } from "react-router-dom";
const last_segment = window.location.pathname.split("/").pop();
//console.log(last_segment);
export default function News() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  /* Api Connection */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const testApi = async () => {
      // Test Get DATA//---;;

      try {
        setLoading(true);
        if (last_segment == "" || last_segment == "news") {
          const usersData = await ApiService.httpGet("/News_api");
          setUsers(usersData);
          //console.log(usersData);
          setLoading(false);
        } else {
          const usersData = await ApiService.httpGet(
            "/News_api/single_post/" + last_segment
          );
          setUsers(usersData);
          setLoading(false);
        }
        // console.log(usersData);
      } catch (err) {
        setLoading(false);
      }
    };

    testApi();
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required *")
      .min(3, "First Name must be at least 3 characters *")
      .max(20, "First Name must not exceed 20 characters *"),
    last_name: Yup.string()
      .required("Last Name is required *")
      .min(3, "Last Name must be at least 3 characters *")
      .max(20, "Last Name must not exceed 20 characters *"),
    email: Yup.string()
      .required("Email is required *")
      .email("Email is invalid *"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    axios
      .post(
        "https://nanban.com/admin/Api/news/subscribe",
        // "http://localhost/stellar/admin/Api/news/subscribe",
        JSON.stringify(data)
      )
      .then((response) => {
        console.log(response);
        document.getElementById("subscribeform").reset();
        if (response.status === 200) {
          Swal.fire({
            text: response.data.message,
            icon: "success",
            type: "success",
          }).then(() => {
            // window.location.reload(); // This will reload the page after the user clicks "OK"
          });
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
          });
        } else {
          Swal.fire({
            text: response.message,
            icon: "error",
            type: "error",
          });
        }

        //console.log(response.data);
      });
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setState({ ...state, [e.target.name]: value });
    console.log(state);
  };

  /* pagination */
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
  let PageSize = 3;

  return (
    <>
      <header className="carousel-item1">
        <MyDiv className="container ipad-container">
          <MyDiv className="row">
            <MyDiv className="col-md-8 mob_arc banner-section">
              <h1 className="banner-heading  mb-0">News</h1>
              {/* <p className="banner-subheading font-weight-light mb-0">
                Eplore the media coverage / news, across the globe on the
                philonthrophic and foundational work <br /> by the Nanban’s,
                translating to it’s core values of helping the community and
                making a difference.{" "}
              </p> */}
            </MyDiv>
            <MyDiv className="col-md-4"></MyDiv>
          </MyDiv>
        </MyDiv>
      </header>
      <Section className="top-banner-relative">
        <img className="common-img" src={media_banner} alt="Story" />
        <MyDiv className="container ipad-container">
          <ul class="breadcrumb page-breadcrumb">
            <li>
              <a href="/">Home</a>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </li>
            <li>News</li>
          </ul>
        </MyDiv>
      </Section>
      <Section className="flip-section bg-white mb-0" id="about">
        <MyDiv className="container ipad-container">
          <MyDiv className="row">
            <MyDiv className="row story_content">
              <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4 three">
                <MyDiv className="row">
                  <div class="col-md-12 col-lg-11 col-sm-12 col-xs-12 text-left media-news">
                    <h4>Subscribe Now for Update Notifications!</h4>
                  </div>
                </MyDiv>

                <section class="capital-section" id="test">
                  <div class="container">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                        <div class="row">
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            id="subscribeform"
                            type="post"
                          >
                            <div class="col-md-6 col-sm-12 form-field">
                              <input
                                name="first_name"
                                type="text"
                                onChange={handleChange}
                                placeholder="First Name *"
                                {...register("first_name")}
                                className={`form-control ${
                                  errors.first_name ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.first_name?.message}
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 form-field">
                              <input
                                name="last_name"
                                type="text"
                                onChange={handleChange}
                                placeholder="Last Name *"
                                {...register("last_name")}
                                className={`form-control ${
                                  errors.last_name ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.last_name?.message}
                              </div>
                            </div>
                            <div class="col-md-12 col-sm-12 form-field">
                              <input
                                name="email"
                                type="email"
                                onChange={handleChange}
                                placeholder="Email *"
                                {...register("email")}
                                className={`form-control ${
                                  errors.email ? "is-invalid" : ""
                                }`}
                              />
                              <div className="invalid-feedback">
                                {errors.email?.message}
                              </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                              <button
                                type="submit"
                                className={`btn banner-btn2 enabled`}
                              >
                                Subscribe
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-md-8 col-sm-12 col-xs-12 col-lg-8  text-left one">
                <MyDiv className="row">
                  {/* <div class="col-md-3 col-lg-1 col-sm-12 col-xs-12 text-center media-bg">
                      <img className="img-fluid" src={world} alt="" class="img-top img-responsive" />
                    </div> */}
                  <div class="col-md-12 col-lg-11 col-sm-12 col-xs-12 text-left media-news">
                    <h4>Nanban News</h4>
                  </div>
                </MyDiv>

                {loading && <h1>Loading</h1>}
                {currentPosts.map((user) => {
                  if (last_segment == "" || last_segment == "news") {
                    if (user.post_link != "" && user.post_link != "null") {
                      // Nanban Foundation section
                      return (
                        <div class="media-item mx-auto">
                          <div id="textbox">
                            <h4 class="alignleft">{user.post_date}</h4>
                            <h4 class="alignright">
                              <a href={user.website_link} target="_blank">
                                {user.website_name}
                              </a>
                            </h4>
                          </div>
                          <br />
                          <br />
                          <h3 className="media_title">{user.post_title}</h3>
                          {/* <p>{parse(user.post_description)}</p> */}
                          <br />
                          <a href={user.post_link}>
                            <i>Read more...</i>{" "}
                          </a>
                        </div>
                      );
                    } else {
                      // Nanban section
                      return (
                        <>
                          <div class="media-item mx-auto">
                            <div id="textbox">
                              <h4 class="alignleft">{user.post_date}</h4>
                              <h4 class="alignright">
                                <a href={user.website_link}>Nanban</a>
                              </h4>
                            </div>
                            <br />
                            <br />
                            <h3 className="media_title">{user.post_title}</h3>
                            {/* <p>{parse(user.post_description)}</p> */}
                            <br />
                            <a href={`news/${user.post_slug}`}>
                              <i>Read more...</i>{" "}
                            </a>
                            {/* {/* <NavLink to={`media/${user.post_slug}`}><i>Read more...</i></NavLink> */}
                          </div>
                        </>
                      );
                    }
                  } else {
                    return (
                      <>
                        <br /> <br />
                        <a href="/news" class="back">
                          Back to News
                        </a>
                        <br />
                        <div class="media-item mx-auto">
                          <div id="textbox">
                            <h4 class="alignleft">{user.post_date}</h4>
                            <h4
                              style={{ textTransform: "capitalize" }}
                              className="alignright"
                            >
                              {user.website_name}
                            </h4>
                          </div>
                          <br />
                          <br />
                          <h3 className="media_title">
                            {user.post_title}
                          </h3>{" "}
                          <br /> <br />
                          {user.post_image ? (
                            <div>
                              <img
                                src={user.post_image}
                                class="img-responsive img-center"
                              />
                              <br /> <br />
                            </div>
                          ) : (
                            <></>
                          )}
                          <p>{parse(user.post_description)}</p>
                        </div>
                        <br />
                        <br />
                        <br />
                        {/* <div id="textbox"><h4 class="alignleft">July 08, 2022</h4><h4 class="alignright">nanban.com</h4></div> */}
                      </>
                    );
                  }
                })}
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 two text-right">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={users.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </MyDiv>
          </MyDiv>
        </MyDiv>
      </Section>
    </>
  );
}
